
.home-section .section-content .event-list .preisspanne span {
	padding: 0 8px;
}

.zeit .show-wide {
	display: inherit !important;
}

.home-section .section-content .content-wrapper {
	padding: 0;
}


.main-content p,
.grid-50-50 .grid-content .main p,
.main-content h1,
.link-list .subtitle, .spielplan .subtitle,
.link-list h3 a, .link-list h3, .spielplan h3 a, .spielplan h3,
.link-list h4, .spielplan h4 {
	line-height: 105%;
}

.marginalspalte p,
.content-with-header .detailview-inner .description, .detailview .detailview-inner .description,
.main-content p {
	@include font-size(14);
	line-height: 120%;

}

.link-list .subtitle, .spielplan .subtitle {
	margin: 0 0 15px 0;
}

.news-single-item h1, .news-list-item h1 {
	line-height: 105%;
	margin-bottom: 15px;
}
.content-with-header .detailview-inner h2.eventTitle, .detailview .detailview-inner h2.eventTitle {
	line-height: 105%;
	@include font-size(50);
}

.content-with-header .detailview-inner, .detailview .detailview-inner {
	padding: 20px 0;
}
/**** Kategorien *****/

/** default **/
.category-font {
	@include font-size(50);
}


/** Schauspiel **/
.category-57 .category-font {
	@include font-size(50);
}

/** Tanz **/
.category-58 .category-font {
	@include font-size(50);
}

/** Musiktheeater **/
.category-59 .category-font {
	@include font-size(50);
}

/** Konzerte **/
.category-60 .category-font {
	@include font-size(50);
}

/** Kinder &  Jungendtheater **/
.category-61 .category-font {
	@include font-size(50);
}

/** Sonstiges **/
.category-62 .category-font {
	@include font-size(50);
}

/** Junger Pfalzbau **/
.category-63 .category-font {
	@include font-size(50);
}

/** Musicmaschine **/
.category-64 .category-font {
	@include font-size(50);
}
