

$defaultFontSize: 20;
$defaultLineHeight: 24;

/*** akzentfarbe - bitte hier ändern ***/
$red: #e63312;

// smallcalendar: days with no event + weekdays
$lightRed: #000;


/*** farben ***/
$blue: #153E72;
$grey: #F2F2F2;
$green: #99D89E;
$black: #0C0000;
$darkRed: #fff;

$redBorder: #f69b99;
$mediumWhite: rgba(255,255,255,.3);


/*** größen ***/
$wide: 1200-32;

$narrow: 800;
$gutter: 16;

/*******************************************************************************
    Schrift
 *******************************************************************************/
@mixin font-size($size: $defaultFontSize) {
	font-size: $size + px;
	/* Standard-Schrift ist 20 Pixel im html definiert worden  20 / 20 = 1 rem */
	//font-size: ($size / $defaultFontSize) +rem;
}

@mixin line-height($size: $defaultLineHeight) {
	line-height: $size + px;
	line-height: ($size / $defaultFontSize) +rem;
}


* {
	box-sizing: border-box;
}

.container.wide {
	width: auto;
	max-width: $wide+px;
}
.container.narrow {
	width: 800px;
	max-width: $narrow+px;
}


.container {
	margin: 0 auto;
	.row {
		float: left;
		width: 100%;

		.cols-100 {
			float: left;
			width: 100%;
			padding: 0 $gutter+px;
		}
		.cols-50 {
			float: left;
			width: 50%;
			padding: 0 $gutter+px;
		}
		.cols-3 {
			float: left;
			width: 33.33%;
			padding: 0 $gutter+px;
		}

		.cols-66 {
			float: left;
			width: 66.66%;
			padding: 0 $gutter+px;
		}
		.cols-33 {
			float: left;
			width: 33.33%;
			padding: 0 $gutter+px;
		}
	}
	.align-right {
		text-align: right;
	}
}


/* Clearer
*******************************************************************************/
.cl,
.clearer,
.clearfix:after,
.cf:after {
	clear: both;
}

.clearfix:before,
.clearfix:after,
.cf:before,
.cf:after {
	content: " ";
	display: table;
}


.show-narrow,
.hidden {
	display: none !important;
}


.spielplan .datum .show-wide {
	display: none !important;
}

.spielplan .datum .show-narrow {
	display: inherit !important;
}
