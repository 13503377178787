.event-list {
	.jahr {
		display: none;
	}
}

.zeit .show-wide {
	display: none;
}

.home-section .section-content .event-list {
	@include font-size(14);
}