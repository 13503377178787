h2.underline {
	font-family: $fontLeagueGothic;
	@include font-size(40);
	@include line-height(44);
	font-weight: 400;
	color: white;
	text-transform: uppercase;
	border: solid $mediumWhite;
	border-width: 0 0 1px 0;
	margin-top: 0;
}
.event-list {
	width: 100%;
	white-space: nowrap;
	table {
		border-collapse: collapse;
		color: white;
		font-family: $fontHelveticaNeueLTStdRoman;
		tr {
			border: solid $mediumWhite;
			border-width: 0 0 1px 0;
		}
		tr:last-child {
			border-width: 0;
		}
		tr:first-child {
			border-width: 0 0 1px 0;
		}
		td {
			padding: 6px 0 6px 0 ;
			margin: 0;
		}
		td:first-child {
			padding: 6px 4px 6px 0;
		}
	}
	.ticket-link {
		text-decoration: none;
	}
}

.home-section .section-content {
	.content-wrapper {
		padding: 0 100px;
		position: absolute;
		top: 50%;
		width: 100%;
		.content,
		.event {
			text-align: center;
			color: white;
			z-index: 100;
			h2,
			span {
				margin: 0;
				text-align: center;
				display: block;
				a {
					color: white;
					text-decoration: none;
					&:hover {
						opacity: 0.3;
						@include transition(opacity, .2s, ease-out);
					}
				}
			}
			.category-title {
				font-family: $fontEngraversGothicBT;
				@include font-size(24);
			}
		}
	}
	.event-list {
		position: absolute;
		bottom: 32px;
		padding: 0 30px;
		@include font-size(20);
		@include line-height(26);
		td.expander {
			width: 10%;
		}
		table {
			margin: 0 auto;
			width: 100%;
		}
		.wochentag {
			width: 147px;
			padding: 0 1%;
		}
		.datum {
			width: 152px;
		}
		.jahr {
			width: 92px;
		}
		.zeit {
			width: 122px;
		}
		.preisspanne {
			width: 182px;
			span {
				padding: 2px 13px 0 13px;
				@include border-radius(16px);
				line-height: 120%;
				background-color: rgba(0, 0, 0, 0.25);
				color: $red;
				display: inline-block;
			}
		}
	}
}

.link-list,
.spielplan {
	h2 {
		font-family: $fontLeagueGothic;
		@include font-size(40);
		@include line-height(44);
		font-weight: 400;
		color: white;
		text-transform: uppercase;
		border: solid $mediumWhite;
		border-width: 0 0 1px 0;
		margin-top: 0;
	}

  .year h2 {
    text-decoration: none;
    text-align: center;
    border:none;
  }

	h3 a,
	h3 {
		text-decoration: none;
		color: white;
		font-family: $fontLeagueGothic;
		@include font-size(40);
		@include line-height(40);
		font-weight: 400;
		text-transform: uppercase;
		margin: 0 0  10px 0;
		padding: 0;
	}
	h4 {
		color: $darkRed;
		font-family: $fontHelveticaNeueLTStd;
		@include font-size(14);
		@include line-height(24);
		font-weight: bold;
		text-transform: uppercase;
		margin: 0;
		padding: 0;
	}
	.subtitle {
		// Text style for "Nach Lewis Car"
		font-family: $fontHelveticaNeueLTStd;
		@include font-size(20);
		@include line-height(24);
		font-weight: 400;
		margin: 0 0 31px 0;
	}
  .row + .row.year {
    margin-top: 60px;
  }
  .year-index {
    display: flex;
    list-style-type: none;
    justify-content: center;
    margin: 0;
    padding: 0;
    li {
      padding: 10px;
      h2 {
        text-decoration: none;
        text-align: center;
        border:none;
      }
      a {
      }
    }
  }
	.event-list {
		color: white;
		font-family: $fontHelveticaNeueLTStd;
		@include font-size(14);
		font-weight: 400;
		.wochentag {
			width: 30px;
		}
		.datum {
		}
		.jahr {
			width: 51px;
		}
		.zeit {
			padding: 0 2%;
		}
		.preisspanne {
			text-align: right;
			span {
				color: $darkRed;
				padding: 2px 6px 2px 7px;
			}
		}
		.ticket-link:hover {
			span {
				@include border-radius(16px);
				background-color: rgba(0, 0, 0, 0.25);
				color: $red;
			}
		}
	}
	.poster {
		width: 30%;
		float: left;
	}
	.content {
		margin-left: 30%;
		padding-left: 20px;
		padding-bottom: 30px;
	}
}
.link-list .poster,
.spielplan .poster {
	img {
		width: 100%;
		height: auto;

	}
}
.content-with-header .event {
	text-transform: uppercase;

}
.content-with-header,
.detailview {
	position: relative;
	background-color: $red;
	&:before {
		content: ' ';
		display: block;
		top: -23px;
		left: 50%;
		width: 0;
		height: 0;
		position: absolute;
		z-index: 1000;
		color: black;
		border-width: 0 24px 24px 24px;
		border-style: solid;
		border-color: transparent transparent $red transparent;
	}
	&:after {
		content: ' ';
		display: block;
		background: url('../Images/icon-i.png') center 8px no-repeat;
		top: -24px;
		left: 50%;
		width: 48px;
		height: 24px;
		position: absolute;
		z-index: 1001;
	}
	.detailview-inner {
		padding: 72px 0 68px 0;
		color: white;
		.category-title {
			display: block;
			text-align: center;
			// Text style for "Schauspiel"
			font-family: $fontEngraversGothicBT;
			@include font-size(24);
			@include line-height(37);
			text-transform: uppercase;
			margin-bottom: 14px;
		}
		h2.eventTitle {
			// Text style for "Tartuffe"
			@include font-size(100);
			@include line-height(90);
			margin: 0 0 63px 0;
			padding: 0;
			text-align: center;
			font-weight: 400;
		}
		.description {
			font-family: $fontHelveticaNeueLTStd;
			@include font-size(20);
			@include line-height(26);
		}
		.sponsoren {
			.sponsored-by {
				font-family: $fontHelveticaNeueLTStd;
				@include font-size(14);
				@include line-height(21);
				margin: 0 0 8px 0;
				padding: 0;
			}
			.sponsor {
				text-align: center;
				padding: 20px 0;
				margin-bottom: 8px;
				img {
					width: 100%;
					height: auto;
				}
			}
		}
	}
	.content-with-header-inner {
		padding: 72px 0 68px 0;
		color: white;
		h4 {
			text-align: center;
			font-family: $fontEngraversGothicBT;
			@include font-size(24);
			@include line-height(24);
			font-weight: 400;
			text-transform: uppercase;
			padding: 0;
			margin: 0;
		}
		h1 {
			text-align: center;
			font-family: $fontLeagueGothic;
			font-weight: 400;
			@include font-size(100);
			@include line-height(100);
			text-transform: uppercase;
			margin: 0 0 63px 0;
			padding: 0;
		}
		p {
			font-family: $fontHelveticaNeueLTStd;
			font-weight: 400;
			@include font-size(20);
			@include line-height(26);
		}
	}
}

/*** linkliste ****/
ul.link-list {
	margin: 0;
	padding: 0;
	li {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}
}


/**** content ****/

.frame-default {
	margin-bottom: 74px;
}

.frame-default.frame-type-header {
	margin-bottom: 0;
}

.cols-66 {
	.frame-default.frame-type-text,
	.frame-default.frame-type-textpic {
		margin-bottom: 30px;
	}

}

table.contenttable {
	width: 100%;
	border-spacing: 0px;
	th,
	td {
		white-space: nowrap;
		border: solid $mediumWhite;
		border-width: 0 0 1px 0;
		padding: 4px;
		width:15%;
		vertical-align: top;
	}
	th:first-child {
		white-space: normal;
		width: 40%;
		text-align: left;
	}
	td.align-center {
		text-align: center;
	}
	tr:last-child th,
	tr:last-child td {
		border: 0;
	}
}

.main-content {
	color: white;
	h1 {
		margin: 0 0 31px 0;
		padding: 0 0 12px 0 ;
		list-style-type: none;
		font-family: $fontLeagueGothic;
		font-weight: 400;
		border: solid $mediumWhite;
		border-width: 0 0 1px 0;
		@include font-size(40);
		@include line-height(40);
		text-transform: uppercase;
	}
	h2 {
		font-family: $fontHelveticaNeueLTStd;
		@include font-size(20);
		@include line-height(26);
		margin-bottom: 34px;
		text-transform: uppercase;
	}
	&.cols-66 h2 {
		margin-bottom: 10px;
	}
	p {
		font-family: $fontHelveticaNeueLTStd;
		@include font-size(20);
		@include line-height(26);
	}
}
.ce-textpic.ce-intext.ce-right.ce-nowrap .ce-bodytext,
.ce-textpic.ce-intext.ce-left.ce-nowrap .ce-bodytext,
.main-content .marginalspalte ,
.marginalspalte {
	margin-bottom: 39px;
	h2,
	h3 {
		font-family: $fontLeagueGothic;
		@include font-size(30);
		text-transform: uppercase;
		margin: 0 0 18px 0;
		padding: 0;
	}
	p {
		font-family: $fontHelveticaNeueLTStd;
		@include font-size(14);
		@include line-height(21);
		margin: 0 0 8px 0;
		padding: 0;
	}
	strong {
		color: $darkRed;
		font-family: $fontHelveticaNeueLTStd;
		@include font-size(14);
		@include line-height(21);
		margin: 0;
		padding: 0;
	}
}
.ce-textpic.ce-intext.ce-right.ce-nowrap .ce-bodytext {
	text-align: right;
}


/** calendar **/
.calendar {
	margin: 0 auto;
	color: $lightRed;
	td {
		font-family: $fontHelveticaNeueLTStd;
		font-weight: 400;
		@include font-size(20);
		@include line-height(30);
		text-align: center;
		a {
			font-family: $fontHelveticaNeueLTStd;
			font-weight: 400;
			@include font-size(20);
			@include line-height(30);
			color: white;
			text-decoration: none;
		}
	}
	.prev,
	.next {
		cursor: pointer;
		width: 30px;
		height: 40px;
		display: block;
		background: url('../Images/arrow-next.png') center center no-repeat;
	}
	.prev {
		background: url('../Images/arrow-prev.png') center center no-repeat;
	}
	.month {
		color: white;
		font-family: $fontLeagueGothic;
		font-weight: 400;
		@include font-size(40);
		@include line-height(30);
		text-transform: uppercase;
		text-align: center;
	}
	.week_days_name {
		// Text style for "Mo"
		width: 37px;
		text-transform: uppercase;
	}
	.today {
		span {

			display: block;
			width: 38px;
			height: 38px;
			@include border-radius(19px);
			background-color: white;
			line-height: 38px;
			color: $red;
			a {
				color: $red;
			}
		}
	}
}

/***** grid elements ******/
.grid-50-50 {
	.grid-content {
		h1,
		h2, h3 {
			font-family: $fontHelveticaNeueLTStd;
			@include font-size(20);
			@include line-height(26);
			margin: 0;
			padding: 0;
			text-transform: uppercase;
			border: 0;
			font-weight: bold;
		}
		.main p {
			margin: 0 0 15px 0;
			font-family: $fontHelveticaNeueLTStd;
			@include font-size(20);
			@include line-height(26);
		}
	}
}

/*** tt-news ***/
.news-list-view .pagebrowser {
  margin-bottom: 1em;
}
.news-single-item,
.news-list-item {
	color: white;
	font-family: $fontHelveticaNeueLTStd;
	@include font-size(20);
	@include line-height(26);
	margin: 0 0 60px 0;
	.header {
		position: relative;
		border: solid $mediumWhite;
		border-width: 0 0 1px 0;
		padding: 0 0 5px 0;
		margin: 0 0 15px 0;
	}
	.news-date {
		position: absolute;
		right: 0;
		bottom: 0;
		padding: 0;
		margin: 0;
		@include font-size(14);
	}
	h1 {
		padding: 0;
		margin: 0;
		font-family: $fontLeagueGothic;
		@include font-size(40);
		@include line-height(40);
		text-transform: uppercase;
	}
	p {
		@include font-size(20);
		@include line-height(26);
		margin: 0 0 8px 0;
		padding: 0;
	}
	.news-single-backlink a,
	.news-list-more a {
		color: $darkRed;
		text-decoration: none;
		&:before {
			content: ' ';
			display: inline-block;
			height: 15px;
			width: 15px;
			background: url('../Images/bullet-morelink.png') center center no-repeat;
		}
	}
	.news-single-backlink a {
		&:before {
			background: url('../Images/bullet-backlink.png') center center no-repeat;
		}

	}
}

/**** störer ****/
.stoerer-wrapper {
	position: absolute;
	top: 200px;
	left: 0;
	opacity: 0;
	width: 100%;
	display: flex;
    justify-content: center;
    padding: 0 20px;
	z-index: 499;
	&.stoerer-wrapper.show {
		opacity: 1;
		transition: opacity .5s ease-in-out;
	}
	.stoerer {
		display: none;
		background: $red;
		@include border-radius(20px);
		line-height: 42px;
		font-family: $fontHelveticaNeueLTStd;
		font-weight: bold;
		padding: 0 0 0 17px;
		@include font-size(14);
		margin-bottom: 15px;
		.label {
			color: $darkRed;
			padding: 0 10px 0 0 ;
		}
		.close-button {
			vertical-align: middle;
			height: 40px;
			width: 16px;
			margin: -4px 15px 0 14px;
			span {
				width: 15px;
			}
		}
	}
}


/**** Kategorien *****/

/** default **/
.category-font {
	font-family: $fontLeagueGothic;
	@include font-size(100);
}


/** Schauspiel **/
.category-57 .category-font {
	font-family: $fontAbrilFatface;
	@include font-size(100);
}

/** Tanz **/
.category-58 .category-font {
	font-family: $fontSixCaps;
	@include font-size(100);
}

/** Musiktheeater **/
.category-59 .category-font {
	font-family: $fontOldStandardTT;
	@include font-size(100);
}

/** Konzerte **/
.category-60 .category-font {
	font-family: $fontArvo;
	@include font-size(100);
}

/** Kinder &  Jungendtheater **/
.category-61 .category-font {
	font-family: $fontLoveYaLikeASister;
	@include font-size(100);
}

/** Sonstiges **/
.category-62 .category-font {
	font-family: $fontHammersmithOne;
	@include font-size(100);
}

/** Junger Pfalzbau **/
.category-63 .category-font {
	font-family: $fontLobster;
	@include font-size(100);
}

/** Musicmaschine **/
.category-64 .category-font {
	font-family: $fontMonoton;
	@include font-size(100);
}
