.apst-wrapper,
.apst-wrapper::before,
.apst-wrapper::after,
.apst-wrapper *,
.apst-wrapper *::before,
.apst-wrapper *::after {
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
        -ms-box-sizing: border-box;
         -o-box-sizing: border-box;
            box-sizing: border-box;
}

.apst-wrapper {
    display: none;
    position: fixed;
    z-index: 99999;
    cursor: pointer;
    box-sizing: border-box;

    /* No blue highlight when clicking on element on mobile devices */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    }

    .apst-top {
        top: 20px;
        }

    .apst-bottom {
        bottom: 20px;
        }

    .apst-left {
        left: 20px;
        }

    .apst-center {
        left: 50%;
        margin-left: -40px;
        }

    .apst-right {
        right: 20px;
        }

    .apst-button {
        display: block;
        position: relative;
        width: 80px;
        height: 80px;
        background-color: #555;
        -webkit-border-radius: 50%;
           -moz-border-radius: 50%;
            -ms-border-radius: 50%;
             -o-border-radius: 50%;
                border-radius: 50%;
        -webkit-transition: all 0.2s;
           -moz-transition: all 0.2s;
            -ms-transition: all 0.2s;
             -o-transition: all 0.2s;
                transition: all 0.2s;
        }

    .apst-button::before {
        content: "";
        display: block;
        position: relative;
        left: 29px;
        top: 33px;
        width: 22px;
        height: 22px;
        border-right: 6px solid white;
        border-top: 6px solid white;
        -webkit-transform: rotate(-45deg);
           -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
             -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
        -webkit-transition: all 0.2s;
           -moz-transition: all 0.2s;
            -ms-transition: all 0.2s;
             -o-transition: all 0.2s;
                transition: all 0.2s;
        }

    .apst-button:hover {
        background-color: #333;
        }

    .apst-button:hover::before {
        border-color: white;
        }

    @media only screen and (max-width: 640px) {
        .apst-center {
            margin-left: -35px;
            }

        .apst-button {
            width: 70px;
            height: 70px;
            }

        .apst-button::before {
            left: 25px;
            top: 27px;
            width: 20px;
            height: 20px;
            }
        }

    @media only screen and (max-width: 400px) {
        .apst-center {
            margin-left: -30px;
            }

        .apst-button {
            width: 60px;
            height: 60px;
            }

        .apst-button::before {
            left: 21px;
            top: 23px;
            width: 18px;
            height: 18px;
            border-width: 5px;
            }
        }
