

.container.wide {
	width: auto;
	max-width: 1200px;
}
.container.narrow {
	width: $narrow+px;
}



.show-wide {
	display: inherit !important;
}

.show-narrow {
	display: none !important;
}

