// Basis: Einbindung der SCSS-Dateien
//
// @author Philip Hoheisel <hoheisel@cyperfection.de>
// @package fuin

@import "../Gulp/node_modules/bootstrap/scss/functions";
@import "../Gulp/node_modules/bootstrap/scss/variables";
@import "../Gulp/node_modules/bootstrap/scss/mixins";
@import "../Gulp/node_modules/bootstrap/scss/root";
//@import "../Gulp/node_modules/bootstrap/scss/reboot";
@import "../Gulp/node_modules/bootstrap/scss/type";
@import "../Gulp/node_modules/bootstrap/scss/images";
@import "../Gulp/node_modules/bootstrap/scss/code";
@import "../Gulp/node_modules/bootstrap/scss/grid";
@import "../Gulp/node_modules/bootstrap/scss/tables";
@import "../Gulp/node_modules/bootstrap/scss/forms";
@import "../Gulp/node_modules/bootstrap/scss/buttons";
@import "../Gulp/node_modules/bootstrap/scss/transitions";
@import "../Gulp/node_modules/bootstrap/scss/dropdown";
@import "../Gulp/node_modules/bootstrap/scss/button-group";
@import "../Gulp/node_modules/bootstrap/scss/input-group";
@import "../Gulp/node_modules/bootstrap/scss/custom-forms";
@import "../Gulp/node_modules/bootstrap/scss/nav";
@import "../Gulp/node_modules/bootstrap/scss/navbar";
@import "../Gulp/node_modules/bootstrap/scss/card";
@import "../Gulp/node_modules/bootstrap/scss/breadcrumb";
@import "../Gulp/node_modules/bootstrap/scss/pagination";
@import "../Gulp/node_modules/bootstrap/scss/badge";
@import "../Gulp/node_modules/bootstrap/scss/jumbotron";
@import "../Gulp/node_modules/bootstrap/scss/alert";
@import "../Gulp/node_modules/bootstrap/scss/progress";
@import "../Gulp/node_modules/bootstrap/scss/media";
@import "../Gulp/node_modules/bootstrap/scss/list-group";
@import "../Gulp/node_modules/bootstrap/scss/close";
@import "../Gulp/node_modules/bootstrap/scss/toasts";
@import "../Gulp/node_modules/bootstrap/scss/modal";
@import "../Gulp/node_modules/bootstrap/scss/tooltip";
@import "../Gulp/node_modules/bootstrap/scss/popover";
@import "../Gulp/node_modules/bootstrap/scss/carousel";
@import "../Gulp/node_modules/bootstrap/scss/spinners";
@import "../Gulp/node_modules/bootstrap/scss/utilities";
//@import "../Gulp/node_modules/bootstrap/scss/print";

.modal-header {
  font-weight: bold;
}

.modal-content, .card {
  background-color: #5e478c;
}

/* compass mixins laden
*******************************************************************************/
@import "../Gulp/node_modules/compass-sass-mixins/lib/compass";

/* plugins
*******************************************************************************/
//@import "plugin/normalize";
@import "plugin/unslider";
@import "plugin/ap-scroll-top";
@import "plugin/magnific";


/* Helper
*******************************************************************************/
@import "helper";
@import "fonts";


/* allgmeines
*******************************************************************************/
@import "layout";
@import "content";
@import "slider";
@import "form";
@import "forms";
//@import "parsley";


@media screen and (max-width: 1200px) {
	@import "1200/helper";
	@import "1200/layout";
}

@media screen and (max-width: 1024px) {
	@import "1024/helper";
	@import "1024/layout";
}

@media screen and (max-width: 900px) {
	@import "900/helper";
	@import "900/layout";
}

@media screen and (max-width: 768px) {
	@import "768/helper";
	@import "768/layout";
	@import "768/content";
}

@media screen and (max-width: 480px) {
	@import "480/helper";
	@import "480/layout";
	@import "480/content";
}

