/*** größen ***/
$wide: 768-32;

$narrow: 768;
$gutter: 16;

.container {
	margin: 0 auto;
	.row {
		.cols-66,
		.cols-33,
		.cols-50,
		.cols-3 {
			width: 100%;
			margin-bottom: 20px;
		}
	}
}

.show-narrow {
	display: inherit !important;
}

.show-wide {
	display: none !important;
}


.container .row {
	margin-left: -8px;
	margin-right: -8px;
}