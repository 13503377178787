.sub-navigation-wrapper .container {
	padding-top: 200px;
}
.red-page {
	min-height: 100%;
	background-color: $red;
	padding-top: 235px;
}

.home-section .section-content .event-list td {
	width: auto !important;
}

#page {
	min-width: auto;
	width:100%;
}

.main-navigation li a {
	padding: 24px 8px 15px 8px;
	@include font-size(20);
}