$wide: 1024-32;


.container.wide {
	width: auto;
}
.container.narrow {
	width: auto;
}



.show-wide {
	display: inherit !important;
}

.show-narrow {
	display: none !important;
}

