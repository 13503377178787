label {
	display:block;
}

textarea,
input {
	margin-bottom: 30px;
}


input[type=tel],
input[type=text],
input[type=password],
input[type=email] {
	width: 100%;
	border: 0;
	@include font-size(16);
	@include line-height(16);
	padding: 15px;
}

input[type=submit] {
	border: none;
	background: $red;
	color: white;
	padding: 20px;
	font-weight: bold;
  border: 3px solid white;
  border-radius: 10px;
	@include font-size(16);
	@include line-height(16);
}

input[type=submit]:hover {
	background: white;
  color: $red;
  text-decoration: none;
}

textarea {
	width: 100%;
	height: 150px;
	border: none;
	resize: none;
}
