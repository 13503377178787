html {
	font-size: 20px;
}
#page,
.top-panel.open {
	min-width: 100%;
}
body.panel-open #page{
	overflow: hidden;
	height:100%;
	min-height:100%;
	max-height:100%;
}
.red-page {
	padding-top: 110px;
}
.sub-navigation-wrapper {
	display: none;
}

.top-panel {
	transition: .5s ease-in-out;
	overflow: hidden;
	height: 86px;
	padding: 0 20px 50px 20px;

	.page-header-title {
		@include font-size(40);
		top: 45px;
	}
	.main-navigation {
		margin-top: 85px;
	}

	.icon-spielplan {
		display: none;
	}
	a.home-link {
		top: 20px;
		left: 20px;

	}
	.logo-pfalzbau {
		background: url(../Images/logo-pfalzbau.png);
		background-size: 100%;
		background-repeat: no-repeat;
		margin-left: -25px;
		width: 38px;
		height: 38px;
	}
	&.open {
		height: 100%;
		position: fixed;
		background: $red;
		color: black;
		z-index: 100000;
		overflow-y: scroll;
	}
}

.burger-wrap {
	display: inline-block;
	position: absolute;
	right: 20px;
	top: 24px;
	width:  40px;
	height: 40px;

	span {
		position: absolute;
		display: block;
		width: 100%;
		height: 2px;
		background: white;
		opacity: 1;
		transition: .25s ease-in-out;
	}
	span:nth-child(1) {
	}
	span:nth-child(2) {
		top: calc(50% - 1px);
	}
	span:nth-child(3) {
		top: calc(50% - 1px);
	}
	span:nth-child(4) {
		bottom: 0;
	}
	&.open {
		span:nth-child(1) {
			opacity: 0;
		}
		span:nth-child(2) {
			transform: rotate(45deg);
		}
		span:nth-child(3) {
			transform: rotate(-45deg);
		}
		span:nth-child(4) {
			opacity: 0;
		}

	}
}
.navigation-level2 {
	display: none;
	& ~ div {
		width: 100% !important;
	}
}
.link-list .poster, .spielplan .poster {
	margin-bottom: 0;
}

.main-navigation {
	> ul > li {
		display: block;
	}
	li a {
		text-align: left;
		padding: 0;
	}
	li a.current {
		font-weight: bold;
	}
	.nav-main-item {
		@include font-size(25);
		@include line-height(70);
		border: solid rgba(255,255,255,0.3);
		border-width: 0 0 1px 0;
	}
	.nav-sub {
		display: block;
		overflow: hidden;
		max-height: 0;
		transition: .5s ease-in-out;
	}
	.nav-sub-item.open ~ ul,
	.nav-main-item.open ~ ul {
		//display: block;
		max-height: 1000px;
	}
	.nav-sub {
		padding-left: 15px;
		li {
			display: block;
		}
		.nav-sub-item {
			@include font-size(20);
			@include line-height(50);
		}
	}
}


.apst-right {
	right: 13px;
}
.apst-bottom {
	bottom: 30px;
}
.apst-button {
	width: 30px;
	height: 30px;
}

.apst-button::before {
	left: 7px;
	top: 8px;
	width: 14px;
	height: 14px;
	border-width: 5px;
}