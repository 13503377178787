body {
	margin: 0;
	background: #111111;
}

html, body {
	width: 100%;
	height: 100%;
	font-size: $defaultFontSize+px;
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
	color: white;
}

#page {
	min-width: 900px;
	width:100%;
	height: 100%;
	min-height: 100% !important;
	position: relative;
}


h1, h2, h3, h4 {
	font-weight: normal;
	font-family: $fontLeagueGothic;
	margin-top: 0;
	padding-top: 0;
}

a,
a:hover,
a:visited {
	color: $darkRed;
	text-decoration: underline;
}

p {
	margin-top: 0;
}

.top-panel {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1000;
	width: 100%;
	height: 166px;
	padding: 0 30px;
	.theater-logo {
		position: absolute;
		left: 32px;
		top: 20px;
	}
	a.home-link {
		color: white;
		font-family: $fontEngraversGothicBT;
		font-size: 24px;
		font-weight: 400;
		line-height: 17px;
		text-decoration: none;
		position: absolute;
		top: 49px;
		left: 31px;
		z-index: 2000;
	}

	.logo-pfalzbau {
		position: absolute;
		left: 50%;
		margin-left: -50px;
		top: 20px;
		display: block;
		height: 80px;
		width: 80px;
		text-indent: -1000em;
		background: url('../Images/logo-pfalzbau.png');
	}
	.icon-spielplan {
		background: url('../Images/icon-Spielplan.png');
		width: 29px;
		height: 32px;
		display: block;
		position: absolute;
		right: 32px;
		top: 49px;
		@include font-size(14);
		color: white;
		text-decoration: none;
		text-align: center;
		padding: 11px 0 0 0;
		z-index: 1000;
	}
	.page-header-title {
		position: absolute;
		text-align: center;
		width: 100%;
		@include opacity(0.1);
		color: white;
		font-family: $fontEngraversGothicBT;
		@include font-size(80);
		font-weight: 400;
		text-transform: uppercase;
		left: 0;
		top: 16px;
	}
}

.close-button {
	width: 30px;
	height: 30px;
	cursor: pointer;
	display: inline-block;
	position: relative;
	z-index: 1000000;

	span {
		$width: 25;
		display: block;
		height: 3px;
		width: $width+px;
		position: absolute;
		top: 50%;
		background: #fff;
		border-radius: 0;
		opacity: 1;
		&:nth-child(1) {
			@include rotate(45deg);
		}
		&:nth-child(2) {
			@include rotate(-45deg);
		}
	}

}

.icon {
	width: 32px;
	height: 32px;
	display: inline-block;
	background-position: center center;
	background-repeat: no-repeat;
}

@each $media in ("tumblr", "facebook", "twitter", "mail", "instagram", "youtube") {
	.icon-#{$media} {
		background-image: url('../Images/icon-#{$media}.png');
	}
}

.social-media-links a {
	text-indent: -1000rem;
	margin-right: 20px;
}

a.icon-facebook {
	margin-right: 10px;
}


/* MAINNAV */
.burger-wrap,
.nav-sub {
	display: none;
}

.main-navigation {
	margin-top: 128px;
	border: solid $mediumWhite;
	border-width: 1px 0 0 0;
	text-align: center;
	position: relative;
	ul {
		margin: 0;
		padding: 0;
	}

	li {
		margin: 0;
		padding: 0;
		display: inline-block;
		list-style-type: none;
		a {
			color: white;
			font-family: $fontEngraversGothicBT;
			@include font-size(24);
			text-transform: uppercase;
			text-decoration: none;
			padding: 24px 12px 15px 12px;
			display: block;
			&.active {
				color: $darkRed;
			}
			&.current {
				color: $darkRed !important;
			}
			&:hover {
				color: $darkRed;
			}
		}
	}
}

.main-navigation.open {
	li {
		a {
			&.active {
				color: white;
			}
			&:hover {
				color: $darkRed;
			}
		}
	}
}


.sub-navigation-wrapper {
	@include transition(height, .2s, ease-out);
	position: absolute;
	z-index: 500;
	width: 100%;
	left: 0;
	height: 0;
	top: 0;
	background: $red;
	display: block;
	overflow: hidden;
	border-bottom: 1px solid $mediumWhite;
	&.open {
		height: 100%;
	}

	.sub-navigation {
		position: absolute;
		top: 0;
		opacity: 0;
		z-index: 1;
		//transition: opacity .5s;
		@include transition(opacity, .2s, ease-out);
		width: 100%;
		&.active {
			opacity: 1;
			z-index: 2;
		}
	}
	.row {
		position: absolute;
		max-width: $narrow+px;
		width: 100%;
	}
	.container {
		padding-top: 200px;
	}

	ul {
		margin: 0;
		padding: 0;
	}

	li {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}
	a {
		color: white;
		font-family: $fontLeagueGothic;
		@include font-size(30);
		@include line-height(36);
		text-transform: uppercase;
		text-decoration: none;
		&.active,
		&:hover {
			color: $darkRed;
		}
	}
	.cols-50 {
		position: relative;
		height: 390px;
		padding: 0 $gutter+px 0 $gutter+px !important;
		&:last-child {
			border: solid #fff;
			border-width: 0 0 0 1px;
		}
	}

	.social-media-links {
		position: absolute;
		left: 62px;
		bottom: 0;
	}
	.close-button {
		position: absolute;
		bottom: 27px;
		left: 50%;
		margin-left: -28px;
	}

}

.mainnav {
	//-webkit-transition: top .5s ease-out;
	//-moz-transition: top .5s ease-out;
	//-o-transition: top .5s ease-out;
	//transition: top .5s ease-out;

	position: fixed;
	z-index: 300;
	width: 100%;
	height: 100%;
	top: -100%;
	left: 0;
	background: #f3716e;

	-webkit-box-shadow: 0px 20px 70px 0px rgba(0, 0, 0, 0);
	-moz-box-shadow: 0px 20px 70px 0px rgba(0, 0, 0, 0);
	box-shadow: 0px 20px 70px 0px rgba(0, 0, 0, 0);
	img {
		-webkit-transition: opacity .3s ease-out .4s;
		-moz-transition: opacity .3s ease-out .4s;
		-o-transition: opacity .3s ease-out .4s;
		transition: opacity .3s ease-out .4s;

		display: block;
		position: relative;
		margin: 0 auto;
		top: 50%;
		margin-top: -200px;
		opacity: 0;
	}
	&.open {
		top: 0;
		-webkit-box-shadow: 0px 20px 70px 0px rgba(0, 0, 0, 0.1);
		-moz-box-shadow: 0px 20px 70px 0px rgba(0, 0, 0, 0.1);
		box-shadow: 0px 20px 70px 0px rgba(0, 0, 0, 0.1);
		img {
			opacity: 1;
		}
	}
}

.red-page {
	min-height: 100%;
	background-color: $red;
	padding-top: 280px;
}

footer {
	color: white;
	a,
	a:visited {
		color: white;
		text-decoration: none;
		display: block;
	}
	@include font-size(14);
	@include line-height(21);
	.mandant {
		background-color: #a0a0a0;
		padding: 64px 0 62px 0;
		.title {
			margin: 0 0 10px 0;
			padding: 0;
			font-family: $fontLeagueGothic;
			font-weight: 400;
			text-transform: uppercase;
			@include font-size(30);
			@include line-height(24);
		}
		a {
			text-decoration: underline;
		}
		.content {
			border-collapse: collapse;
			td {
				padding: 0;
				margin: 0;
			}
		}
	}
	.lu {
		padding: 20px 0 14px 0;
		background-color: #909090;
	}
}

.navigation-level2 ul {
	margin: 0;
	padding: 0;
	li {
		margin: 0;
		padding: 0;
		list-style-type: none;
		color: $darkRed;
		font-family: $fontLeagueGothic;
		font-weight: 400;
		border: solid $mediumWhite;
		border-width: 0 0 1px 0;
		@include font-size(40);
		@include line-height(40);
		text-transform: uppercase;

		a {
			padding: 42px 0 12px 0;
			color: white;
			text-decoration: none;
			display: block;
			&:hover {
				color: $darkRed;
			}
		}
	}
	li:first-child a {
		padding: 0 0 12px 0;
	}
	li.active {
		border-color: $darkRed;
		a {
			color: $darkRed
		}
	}
}

/*** to top button ***/
.apst-button {
	background: $red;
	border: 1px solid $mediumWhite;
	&:hover {
		background: $darkRed;
	}
}

button.mfp-close {
	position: absolute;
	right: -19px !important;
	width: 44px !important;
	top: 10px;
	figure {
		position: relative;
		z-index: -1;
	}
}

/* SECTIONS */

ul.home-section,
li.home-section {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.home-section {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 100;

	//background-image: url();
	background-repeat: no-repeat;
	background-position: center center;
	background-attachment: fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	.section-content .event-list table {
		max-width: $wide+px;
		width: 100%;
	}

}

.section-content {
	background: rgba(0, 0, 0, 0.3) url(../img/bottom-shadow.png) repeat-x bottom fixed;
	width: 100%;
	height: 100%;
  .row {
    display: block;
  }
}

.slide-text {
	position: relative;
	display: block;
	top: 40%;
	margin: 0 auto;
}

.slide-ow {
	position: relative;
	display: block;
	top: 26%;
	margin: 0 auto;
}

.section-ow .section-content, .section10 .section-content {
	background: rgba(0, 0, 0, 0);
}

.section-ow .section-content img {
	height: 65%;
	max-height: 591px;
}

.tickets {
	position: absolute;
	width: 100%;
	bottom: 32px;
}

/* Spielplan */

.special-h1 {
	position: fixed;
	width: 100%;
	z-index: 302;
	margin-top: 48px;
}

.special-h1 img {
	margin: 0 auto;
	display: block;
}

/* Spielplan */

.spielplan-content {
	box-sizing: border-box;
	width: 100%;
	position: relative;
	min-height: 100%;
	background: #f3716e;
	padding-top: 190px;
	padding-bottom: 80px;
}

.list-headline {
	display: inline-block;
	width: 100%;
	height: auto;
	margin-top: 64px;
	margin-bottom: 64px;
}

.list-headline:first-child {
	margin-top: 0px;
}

.list-headline img {
	margin: 0 auto;
	display: block;
}

.list-content {
	display: inline-block;
	position: relative;
	width: 100%;
	height: auto;
}

.list-content img {
	margin: 0 auto;
	display: block;
}

/* Neuigkeiten */

.neuigkeiten-content {
	width: 100%;
	min-height: 100%;
	background: #f3716e;
	padding-top: 190px;
	padding-bottom: 80px;
	box-sizing: border-box;
}

.neuigkeiten-content img {
	margin: 0 auto;
	display: block;
}

.detail-info {
	position: absolute;
	width: 100%;
	bottom: 0px;
	background: url(../img/detail-info-bg.png) repeat-x bottom;
}

.detail-info img {
	display: block;
	margin: 0 auto;
}

.details-content {
	width: 100%;
	min-height: 100%;
	background: #f3716e;
	padding-top: 72px;
	padding-bottom: 80px;
	box-sizing: border-box;
}

.details-content img {
	margin: 0 auto;
	display: block;
}

a.slide-text {
	-webkit-transition: opacity .3s ease-out;
	-moz-transition: opacity .3s ease-out;
	-o-transition: opacity .3s ease-out;
	transition: opacity .3s ease-out;
	opacity: 1;
}

a.slide-text:hover {
	opacity: 0.2;
}
