/*** größen ***/
$wide: 1024-32;

$narrow: 800;
$gutter: 16;

.container {
	margin: 0 auto;
	.row {
		margin-left: 0;
		margin-right: 0;

		.cols-50 {
			float: left;
			width: 50%;
			padding: 0 $gutter+px;
		}
	}
}

.show-narrow {
	display: inherit !important;
}

.show-wide {
	display: none !important;
}


