
/* SLIDER */

.unslider {
	height: 100%;
}

.my-slider {
	width: 100%;
	height: 100% !important;
}
.unslider li, .unslider ol, .unslider ul {
	height: 100%;
}

.unslider-arrow {
	position: absolute;
	left: 20px;
	z-index: 222;
	cursor: pointer;
	top: 48%;
}

.unslider-nav {
	display: none;
}

.unslider-arrow.next {
	right: 32px;
	color: rgba(0,0,0,0);
}

.unslider-arrow.next:before {
	content: "";
	display: block;
	width: 32px;
	height: 25px;
	top: 0;
	left: 0;
	background: url(../img/next.png) no-repeat;
}

.unslider-arrow.prev {
	left: 32px;
	color: rgba(0,0,0,0);
}

.unslider-arrow.prev:before {
	content: "";
	display: block;
	width: 32px;
	height: 25px;
	top: 0;
	left: 0;
	background: url(../img/prev.png) no-repeat;
}
