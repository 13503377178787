

html, body {
	font-size: 14px !important;
}


#page .red-page,
#page section {
	overflow: hidden;
}

.top-panel a.home-link {
	@include font-size(20);
}