// Stile für Formulare
//
// @author Lars Beuse <lars@cyperfection.de>
// @version $Id$
// @package ludwigshafen


// ******************************************* Structure ***********************

.tx-powermail .form-row {
  margin: 5px 0;
  display: block;
}

.form-cell {
	float: left;
}

.form-cell-city {
	width: 320px;
}

.form-cell-postalcode {
	margin-right: 30px;
	width: 80px;
}

.form-cell-street {
	margin-right: 30px;
	width: 350px;
}

.form-cell-streetno {
	width: 50px;
}

.form-cell-50-50-first {
	margin-right: 30px;
	width: 200px;
}

.form-cell-50-50-last {
	width: 200px;
}



// ******************************************* Labels ************************

.form-label-checkbox {
	display: inline;
	padding-right: 15px;
}



// ******************************************* Fields ************************

.form-field {
	@include box-sizing("border-box");
	display: block;
	outline: none;  /* für Chrome */
	padding: 5px 5px 3px 5px;
	width: 100%;
}

.form-field-select,
.form-field-text,
.form-field-textarea {
	@include border-radius(5px);
	@include box-shadow(2px, 2px, 2px, 0, #cecece, true);
    background: #f2f2f2;
	border: 1px solid #f2f2f2;
}

.form-field-text {
	width: 100%;
	border: none;
}

.form-field-textarea {
	height: 100px;
	height: 10rem;
}

.form-submit {
	//@extend .arrow-small-cyan-right;
	@include font-size(13);
	border: none;
	//color: $cyan;
	float: right;
	font-family: DINWeb-Medium, Arial, Helvetica, sans-serif;
	padding-left: 20px;
	text-transform: uppercase;
	&:hover, &:focus {
		text-decoration: underline;
	}
}


// ******************************************* Error **************************

.form-field-error {
	border-color: #f00;
}



// ******************************************* Required Marker ****************
.required-marker {
	//color: $cyan;
	font-weight: bold;
	@include font-size(16);
}

.required-explanation {
	@include font-size(10);
}



// ***************************************** Freecap **************************
.freecap {
	margin-top: 20px;
}

.freecap-image {
	margin-top: 10px;
	margin-bottom: 10px;
}


// Overwrite bootstrap button
.btn {
	@include font-size(13);
	border: none;
	background-color: transparent;
	//color: $cyan;
	font-family: DINWeb-Medium, Arial, Helvetica, sans-serif;
	text-transform: uppercase;
	padding: 0 12px 0 0;
	&:hover, &:focus {
		outline: 0;
	}

	&:hover {
		text-decoration: underline;
	}
}


// Hide powermail form legend
.powermail_fieldset > .powermail_legend {
	display: none;
}
/* Customize the label (the container) */
.powermail_fieldwrap_type_check > label,
.powermail_fieldwrap_type_radio > label {
  margin-bottom: 5px;
}
/* Hide the browser's default checkbox */
.checkbox,
.radio {
  display: inline-block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 20px;


  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: relative;
    padding-left: 32px;
    line-height: 25px;
  }

  /* Style the checkmark/indicator */
  .checkmark:after,
  .checkmark:before {
    position: absolute;
    content: "";
    display: inline-block;
    top: -2px;
    left: 0px;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background: white;
    margin-right: 8px;
  }
  .checkmark:after {
    display: none;
    top: 7px;
    left: 9px;
    width: 8px;
    height: 8px;
    background-color: $red;
  }
  & input:checked ~ .checkmark:after {
    display: inline-block;
  }
}

.checkbox {
  .checkmark:after,
  .checkmark:before {
    border-radius: 0;
    background: white;
    margin-right: 8px;
  }
  .checkmark:after {
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid $red;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

